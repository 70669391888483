<template>
  <div class="detail-containter">
    <nav-menu></nav-menu>
    <div class="detail-body">
      <div class="containter">
        <div class="detail-title">{{title}}</div>
        <div class="detail-content">
          <div v-html="detail"></div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import { getDetail, getDetailByToken } from './../lib/api/index'
export default {
  name: 'detail',
  components: {},
  data() {
    return {
      id: -1,
      title:'',
      detail:""
    }
  },
  created() {
    console.log(this.$route.query.id)
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
    if(window.localStorage.getItem("token")){
      getDetailByToken(this.$route.query.id).then(res=>{
        if(res.code==200){
          this.detail=res.data.remark
          this.title=res.data.name
        }
      })
    }else{
      getDetail(this.$route.query.id).then(res=>{
        if(res.code==200){
          this.detail=res.data.remark
          this.title=res.data.name
        }
      })
    }
  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.detail-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .detail-body {
    padding: 20px 0;

    .containter {
      .detail-title {
        color: #333;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
      }

      .detail-content {
        padding: 0 40px;
        font-size: 16px;
        color: #666;
        line-height: 26px;
        text-align: justify;
      }
    }
  }
}
</style>
